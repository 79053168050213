* {
  margin: 0;
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  --light-blue: #f4f5fb;
  --light-fuschia: #f3e1e4;
  --fuschia: #d47a8c;
  --dark-fuschia: #c5314f;
  --darker-fuschia: #a0263e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-enter, .page-exit-active {
  opacity: 0;
}

.page-enter-active, .page-exit {
  opacity: 1;
}

nav {
  background-color: white;
}

.navbar-brand {
  padding-bottom: 0px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
}

.navbar-expand-lg .navbar-nav .nav-link.active {
  color: var(--dark-fuschia);
  font-weight: 600;
}

.page-content {
  min-height: calc(100vh - 81px);
  margin-bottom: -80px;
}

.page-content:after {
  content: "";
  display: block;
  height: 80px;
}

h1 {
  margin-bottom: 10px;
}

h2 {
  margin-bottom: 25px;
}

h3 {
  margin-bottom: 15px;
}

.fixed-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  z-index: -2;
}

.image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: -1;
}

.home-section-1, .leadership-section-1, .news-section-1, .our-approach-section-1, .partnerships-section-1 {
  height: min(400px, calc(100vh - 81px));
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
}

.home-section-1-content, .leadership-section-1-content, .news-section-1-content, .our-approach-section-1-content, .partnerships-section-1-content {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 50px 60px;
}

.home-section-2 {
  background-color: white;
  padding: 60px;
  display: flex;
  justify-content: center;
}

.home-section-2-content, .home-section-3-content {
  max-width: 1050px;
}

p,a {
  font-size: 18px;
}

a {
  color: var(--dark-fuschia);
}

a.no-fuschia {
  color: #212529;
}

.fa-angle-right, .fa-angle-down, .fa-angle-up {
  margin-left: 8px;
}

.home-section-2-content p {
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: left;
}

.home-section-2-content h3 {
  text-align: left;
}

.home-section-3 {
  background-color: var(--light-blue);
  padding: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.news-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.home-section-3 .news-cards {
  margin-bottom: 40px;
}

.news-card {
  text-align: left;
  width: 350px;
  padding: 30px;
  margin: 30px;
  background-color: var(--light-blue);
  border-radius: 10px;
  border: solid 1px #ddd;
  box-shadow: -5px 5px 5px 0px #ddd;
}

.news-card p:last-of-type {
  margin-bottom: 0px;
}

.home-section-3 .news-card, .news-section-3 .news-card {
  background-color: white;
}

.our-approach-section-1-content {
  max-width: 660px;
}

.our-approach-section-2, .our-approach-section-2b, .our-approach-section-3, .our-approach-section-4 {
  padding: 60px;
  background-color: white;
  display: flex;
  justify-content: center;
}

.our-approach-section-2b, .our-approach-section-4 {
  background-color: var(--light-blue);
}

.our-approach-section-3 {
  position: relative;
}

.our-approach-section-3-content, .our-approach-section-4-content {
  z-index: 1;
}

.background-icon {
  position: absolute;
  bottom: -250px;
  right: calc(50vw - 233px);
  color: var(--light-fuschia);
  font-size: min(calc(100vw - 60px), 500px);
  opacity: 0.7;
}

.our-approach-section-2-content, .our-approach-section-2b-content, .our-approach-section-3-content, .our-approach-section-4-content {
  max-width: 850px;
}

.our-approach p,h2 {
  text-align: left;
}

.our-approach-section-2-content img {
  width: 100%;
  margin-top: 30px;
}

.partnerships-link {
  margin-top: 40px;
}

.leadership-section-2 h1, .leadership-section-3 h2, .partnerships-section-3 h3, .news-section-2 h1, .news-section-3 h1 {
  margin-bottom: 30px;
}

.news-section-2 {
  padding: 60px;
  background-color: white;
}

.news-section-3 {
  padding: 60px;
  background-color: var(--light-blue);
}

.leadership .fixed-image {
  object-position: center;
}

.leadership-section-1-content {
  max-width: 600px;
}

.leadership-section-2 {
  background-color: var(--light-blue);
  padding: 60px;
}

.team-members {
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
}

.team-member {
  display: flex;
  margin-bottom: 30px;
  border-radius: 10px;
  border: solid 1px #ddd;
  box-shadow: -5px 5px 5px 0px #ddd;
  padding: 20px;
  background-color: white;
  text-align: left;
}

.team-member h3 {
  margin-bottom: 5px;
}

.team-member-title {
  font-weight: 600;
}

.team-member.right-aligned {
  text-align: right;
}

.team-member img {
  border-radius: 50%;
  width: 250px;
  height: 250px;
  margin: 30px;
  object-fit: cover;
}

.team-member-details {
  margin: 30px;
}

.team-member-bio {
  overflow-y: hidden;
}

.team-member-bio.collapsed {
  height: 135px;
}

.read-more {
  margin-top: 1rem;
}

.leadership-section-3 {
  background-color: white;
  padding: 60px;
}

.leader-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.leader-card {
  width: 350px;
  padding: 30px;
  margin: 30px;
  background-color: var(--light-blue);
  border-radius: 10px;
  border: solid 1px #ddd;
  box-shadow: -5px 5px 5px 0px #ddd;
}

.leader-card h2 {
  text-align: center;
}

.leader-card ul {
  padding-left: 30px;
}

.advisors {
  text-align: left;
  display: flex;
  justify-content: center;
}

.partnerships-section-1-content {
  max-width: 750px;
}

.partnerships-section-2 {
  background-color: var(--light-blue);
  padding: 60px;
}

.partnerships-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.partnership-card {
  width: 400px;
  background-color: white;
  padding: 30px;
  margin: 20px;
  border-radius: 10px;
  border: solid 1px #ddd;
  box-shadow: -5px 5px 5px 0px #ddd;
}

.partnership-card ul {
  padding-left: 30px;
  margin-bottom: 0px;
}

.partnership-card li {
  text-align: left;
  margin-bottom: 5px;
  font-size: 18px;
}

.partnerships-section-3 {
  background-color: white;
  padding: 60px;
}

.partnerships form {
  width: min(500px, 100%);
  margin: 30px;
}

.form-control:focus {
  border-color: var(--fuschia);
  box-shadow: 0 0 0 0.2rem var(--light-fuschia);
}

.btn-outline-dark {
  background-color: white;
  border-color: var(--dark-fuschia);
  color: var(--dark-fuschia);
}

.btn-outline-dark:active {
  background-color: var(--dark-fuschia) !important;
  border-color: var(--dark-fuschia) !important;
  color: white !important;
}

.btn-outline-dark:focus {
  border-color: var(--dark-fuschia);
  box-shadow: 0 0 0 0.2rem var(--light-fuschia) !important;
}

.contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contact-info {
  margin: 30px;
  border-left: solid 1px #ced4da;
  padding-left: 60px;
}

.contact-method {
  display: flex;
  text-align: left;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.contact-method .fas {
  margin-right: 20px;
  width: 30px;
  font-size: 30px;
}

.footer {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.footer p {
  font-size: 14px;
  margin-bottom: 0px;
}

.link {
  color: var(--dark-fuschia);
}

@media all and (max-width: 1126px) {
  .contact-info {
    margin-bottom: 0px;
    border-left: none;
    padding-left: 0px;
  }
}

@media all and (max-width: 991px) {
  .image-overlay {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .home-section-1, .leadership-section-1, .news-section-1, .our-approach-section-1, .partnerships-section-1 {
    padding: 0px;
  }

  .home-section-1-content, .our-approach-section-1-content, .leadership-section-1-content, .partnerships-section-1-content, .news-section-1-content {
    background-color: transparent;
    padding: 30px 20px;
  }

  .partnerships-section-2, .partnerships-section-3, .news-section-2, .news-section-3, .our-approach-section-2, .our-approach-section-2b, .our-approach-section-3, .our-approach-section-4, .home-section-2, .home-section-3 {
    padding: 30px;
  }

  .news-section-2, .news-section-3 {
    padding-top: 60px;
  }

  .leadership-section-2, .leadership-section-3 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .team-member {
    flex-wrap: wrap;
    justify-content: center;
  }

  .team-member-details {
    margin: 10px;
  }

  .team-member-details h3, p.team-member-title {
    text-align: center;
  }

  .partnerships form {
    margin-left: 0px;
    margin-right: 0px;
  }

  .partnerships-section-3 h3 {
    margin-bottom: 0px;
  }

  .leader-card, .news-card {
    margin-top: 0px;
  }
}

@media all and (max-width: 800px) and (min-height: 900px) {
  .partnerships-image {
    object-position: 50% -100px;
  }
}

@media all and (max-width: 700px) {
  .background-icon {
    bottom: -150px;
    right: calc(50vw - 140px);
    font-size: 300px;
  }

  .partnership-card, .leader-card, .news-card {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media all and (hover: hover) {
  .navbar-expand-lg .navbar-nav .nav-link:hover {
    color: var(--dark-fuschia);
  }

  .link:hover {
    cursor: pointer;
    color: var(--darker-fuschia);
  }

  a:hover {
    text-decoration: none;
    color: var(--darker-fuschia);
  }

  a.no-fuschia:hover {
    color: black;
  }

  .btn-outline-dark:hover {
    background-color: var(--dark-fuschia) !important;
    border-color: var(--dark-fuschia) !important;
    color: white !important;
  }

  .news-card:hover {
    cursor: pointer;
  }
}
